<template>
  <transition name="menu">
    <form-keamanansurat :is-edit="true"/>
  </transition>
</template>

<script>
import formKeamanansurat from './components/formKeamanansurat'

export default {
  name: 'EditKemanansurat',
  components: { formKeamanansurat }
}
</script>

